import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import ArticleAuthorInfo from "../components/ArticleAuthorInfo"
import hljs from "highlight.js"
import "highlight.js/styles/googlecode.css"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import { DiscussionEmbed } from "disqus-react"
import Helmet from "react-helmet"
import { getContentInfo } from "../components/ContentInfo"
import slugVi from "../utils/slugVi"

const ZoomImage = (props) => {
  return (
    <Zoom>
      <img
        alt={props.alt}
        {...props}
      />
    </Zoom>
  )
}
const disqusConfig = (id) => {
  return {
    shortname: "blog-ncoad5txr3",
    config: { identifier: id }
  }
}
const ArticleTemplate = ({ data }) => {
  const article = data.strapiArticle

  useEffect(() => {
    hljs.initHighlighting()
    return () => {
      hljs.initHighlighting.called = false
    }
  }, [])
  const title = article?.title
  const contentInfo = getContentInfo(article?.content)
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title}/>
        <meta property="og:url" content={`https://blog.classfunc.com/post/${slugVi(title)}`}/>
        {/*<meta property="og:image" content={title}/>*/}
        <meta name="description" content={title}/>
        <meta property="og:description" content={contentInfo}/>
        <meta name="twitter:description" content={contentInfo}/>
        <meta property="og:type" content="article"/>
      </Helmet>
      <h1>{article.title}</h1>
      <ArticleAuthorInfo article={article}/>
      {
        article.image &&
        <Img fluid={article.image?.childImageSharp?.fluid}/>
      }

      <ReactMarkdown source={article.content}
                     renderers={{
                       image: ZoomImage
                     }}
      />
      <hr/>
      <DiscussionEmbed {...disqusConfig(article.id)} />

    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
    query ($id: String!) {
        strapiArticle(id: { eq: $id }) {
            id
            title
            content
            created_at
            updated_at
            image {
                childImageSharp {
                    fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            author {
                id
                username
                avatar {
                    publicURL
                }
            }
        }
    }
`
